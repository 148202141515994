import React from 'react'
import PropTypes from 'prop-types'
import {Table} from 'react-bootstrap'
import {useTable} from 'react-table'

import styles from 'components/base_table.module.scss'

/**
 * Generic table component that wraps all the setup require for a table from
 * react-table.
 * It uses react-bootstrap table as a template for styling.
 * @example
 * const data = [{name: 'Test', lastname: 'example'}]
 * const columns = [
 *   {Header: 'A header', accessor: 'name'},
 *   {Header: 'Lastname', accessor: 'lastname'}
 * ]
 * return (<BaseTable data={data} columns={columns} />)
 */
const BaseTable = (props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows
  } = useTable(props)

  const getNumericClass = (className = '') => {
    if (className.includes('--numeric')) {
      return styles['column--numeric']
    }
    return ''
  }

  const renderTH = (col) => {
    return (
      <th
        className={getNumericClass(col.className)}
        {...col.getHeaderProps()}
      >
        {col.render('Header')}
      </th>
    )
  }

  const renderHeaderRow = (header) => {
    return (
      <tr {...header.getHeaderGroupProps()}>
        {header.headers.map(renderTH)}
      </tr>
    )
  }

  const renderCell = (cell) => {
    return (
      <td
        className={getNumericClass(cell.column.className)}
        {...cell.getCellProps()}
      >
        {cell.render('Cell')}
      </td>
    )
  }

  const renderRow = (row) => {
    prepareRow(row)
    return (
      <tr {...row.getRowProps(props.getRowProps(row))}>
        {row.cells.map(renderCell)}
      </tr>
    )
  }

  return (
    <Table
      id={props.id}
      className={styles['base-table']}
      responsive
      borderless
      {...getTableProps()}
    >
      <thead>{headerGroups.map(renderHeaderRow)}</thead>
      <tbody {...getTableBodyProps()}>{rows.map(renderRow)}</tbody>
    </Table>
  )
}

BaseTable.propTypes = {
  /** Unique table identifier */
  id: PropTypes.string.isRequired,
  /** The actual info to fill the table with */
  data: PropTypes.array,
  /** Definition of the table's columns */
  columns: PropTypes.arrayOf(PropTypes.shape({
    /** Text to display as header of the column */
    Header: PropTypes.string.isRequired,
    /** Key inside each data item wtih the value for this column */
    accessor: PropTypes.string.isRequired
  })).isRequired,
  getRowProps: PropTypes.func
}

BaseTable.defaultProps = {
  data: [],
  getRowProps: () => ({})
}

export default BaseTable
