/** @module Mixpanel */
import Mixpanel from 'mixpanel-browser'

import {fetchAccount} from 'actions/account_actions'

/**
 * If a Mixpanel key is provided, initialize the lib for use inside the app.
 * @method
 * @param {object} user - The logged user to use for the current session
 */
export const initMixpanel = async (user) => {
  const {REACT_APP_MIXPANEL_API_KEY} = process.env
  if (REACT_APP_MIXPANEL_API_KEY) {
    Mixpanel.init(REACT_APP_MIXPANEL_API_KEY, {ignore_dnt: true})
    Mixpanel.identify(user.userId)
    Mixpanel.people.set({
      email: user.email,
      name: `${user.firstName} ${user.lastName}`
    })
    let account = 'Aki'
    if (user.accountId) {
      const data = await fetchAccount(user.accountId)
      account = data.accountName
    }
    Mixpanel.people.set({account})
  }
}

export const triggerEvent = (event, data = {}) => {
  const {REACT_APP_MIXPANEL_API_KEY} = process.env
  if (!REACT_APP_MIXPANEL_API_KEY) {
    return
  }
  Mixpanel.track(event, data)
}
