/**
 * @category Validators
 * @module User
 */
import {ACCESS_OPTIONS} from 'lib/constants/users'

const EMAIL_REGEXP = /\S+@\S+\.\S+/

/**
 * Validates a user has the following
 *  - First name is provided
 *  - Last name is provided
 *  - Email passes the regexp test
 *  - If access level is account, at least one subaccount is selected
 *  - If access level is campaign, at least one campaign is selected
 * @param {object} user - The values to validate
 * @return {object} The list of fields with errors found, if the user is valid,
 * it is an empty object
 */
export const validateUser = (user) => {
  const {firstName, lastName, email, access, subAccountIds, campaignIds} = user
  const errors = {}
  if (!firstName) {
    errors.firstName = 'Please introduce a first name for the user'
  }
  if (!lastName) {
    errors.lastName = 'Please introduce a last name for the user'
  }
  if (!EMAIL_REGEXP.test(email)) {
    errors.email = 'Please introduce a valid email'
  }
  if (access === ACCESS_OPTIONS.account.id && subAccountIds.size === 0) {
    errors.access = 'Please select at least one account'
  }
  if (access === ACCESS_OPTIONS.campaign.id && campaignIds.size === 0) {
    errors.access = 'Please select at least one campaign'
  }
  return errors
}

