import React, {useState, useContext, useEffect, useMemo} from 'react'
import {observer} from 'mobx-react'
import {
  Container,
  Form
} from 'react-bootstrap'
import {Link, useParams} from 'react-router-dom'

import BaseTable from 'components/BaseTable'
import COGSReport from 'displays/account/CogsReport'
import {triggerEvent} from 'lib/mixpanel'
import {RootContext} from 'stores/RootStore'
import {AM_TYPE_USER} from 'lib/constants/users'
import {
  formatDate,
  formatNumber,
  formatDollars,
  formatPercentage
} from 'lib/formatting'

const BASE_CLASS = 'account-wrapper'

/**
 * Displays an account and its children campaigns
 */
const AccountWrapper = observer(() => {
  const [showBilling, setShowBilling] = useState(false)
  const params = useParams()
  const {
    user,
    accountStore,
    campaignStore
  } = useContext(RootContext)
  useEffect(() => {
    accountStore.getAccount(params.accountId)
    campaignStore.fetchCampaigns(params.accountId)
  }, [params.accountId, campaignStore, accountStore])

  const renderNameCell = ({value, row}) => {
    return <Link to={`/campaigns/${row.original.campaignId}`}>{value}</Link>
  }

  // TODO: Change header strings to localized ones
  const columns = useMemo(() => {
    const columns = [
      {
        Header: 'Campaign',
        accessor: 'campaignName',
        Cell: renderNameCell
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        Cell: ({value}) => formatDate(value)
      }, {
        Header: 'End Date',
        accessor: 'endDate',
        Cell: ({value}) => formatDate(value)
      }
    ]
    if (user.userType === AM_TYPE_USER || user.hasPerformancePermission) {
      return [
        ...columns,
        {
          Header: 'Delivery Spend',
          accessor: 'overview.deliverySpend',
          className: 'column--numeric',
          Cell: ({value}) => formatDollars(value)
        }, {
          Header: 'Impressions',
          accessor: 'overview.impressions',
          className: 'column--numeric',
          Cell: ({value}) => formatNumber(value)
        }, {
          Header: 'Clicks',
          accessor: 'overview.clicks',
          className: 'column--numeric',
          Cell: ({value}) => formatNumber(value)
        }, {
          Header: 'CTR',
          accessor: 'overview.ctr',
          className: 'column--numeric',
          Cell: ({value}) => formatPercentage(value)
        }, {
          Header: 'Completes',
          accessor: 'overview.completes',
          className: 'column--numeric',
          Cell: ({value}) => formatNumber(value)
        }, {
          Header: 'VCR',
          accessor: 'overview.vcr',
          className: 'column--numeric',
          Cell: ({value}) => formatPercentage(value)
        }
      ]
    }
    return columns
  }, [user.userType, user.hasPerformancePermission])

  const toggleTable = ({target}) => {
    setShowBilling(target.checked)
    triggerEvent('Metrics Toggle', {
      metrics: target.checked ? 'Billing' : 'Performance'
    })
  }

  const renderToggle = () => {
    if (accountStore.hasBillingReport && user.hasBillingPermission) {
      return (
        <Form>
          <Form.Check.Label htmlFor='report-type-switch' className='mr-2'>
            Performance Metrics
          </Form.Check.Label>
          <Form.Check
            type='switch'
            id='report-type-switch'
            label='Billing Metrics'
            onChange={toggleTable}
            checked={showBilling}
            inline
          />
        </Form>
      )
    }
  }

  const renderTable = () => {
    if (showBilling) {
      return <COGSReport />
    }
    return (
      <BaseTable
        id='campaigns-table'
        data={campaignStore.campaigns}
        columns={columns}
      />
    )
  }

  return (
    <Container className={BASE_CLASS} fluid>
      <h5 className='mb-5'>{accountStore.accountName}</h5>
      {renderToggle()}
      {renderTable()}
    </Container>
  )
})

export default AccountWrapper
