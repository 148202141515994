/**
 * @category Hooks
 * @module useForm
 */
import {useState, useEffect} from 'react'

const emptyValidator = () => ({})

/**
 * Custom hook that allows generic form manipulation
 * @param {object} initialValues - The starting form state of the form,
 * If this value is overriden the flags get reset too.
 * @param {function} onSubmit - To be called when changes are saved.
 * @param {function} [validator = () => ({})] - Function to be run everytime a
 * field is changed, it must return an object with the set of errors found.
 * @return {object} An object with the following values
 *  values: The form's current state
 *  errors: The form's current set of errors
 *  pristine: True if the current values are initial or recently saved
 *  valid: True when the set of errors is empty
 *  touched: An object with the fields that have been modified
 *  submitting: True while the submit action is being awaited for
 *  onChangeInput: Action that handles updating the form values
 *  handleSubmit: Action that handles saving the form values
 */
const useForm = ({
  initialValues,
  validator = emptyValidator,
  onSubmit
}) => {
  const [errors, setErrors] = useState({})
  const [values, setValues] = useState(initialValues || {})
  const [touched, setTouched] = useState({})
  const [submitting, setSubmitting] = useState(false)

  // Enable the user to reinit the initial values
  useEffect(() => {
    setValues(initialValues)
    setTouched({})
  }, [initialValues])

  // Validate the form every time a field is updated
  useEffect(() => {
    const errors = validator(values)
    setErrors(errors)
  }, [values, validator])

  const onChangeInput = (event) => {
    setValues({...values, [event.target.name]: event.target.value})
    setTouched({...touched, [event.target.name]: true})
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
    setSubmitting(true)
    // Clear form error while saving
    setErrors({})
    try {
      await onSubmit(values)
      setSubmitting(false)
      setTouched({})
    } catch (err) {
      setSubmitting(false)
      setErrors({form: 'An error occured while saving, please try again'})
    }
  }

  return {
    values,
    errors,
    pristine: Object.keys(touched).length === 0,
    valid: Object.keys(errors).length === 0,
    touched,
    submitting,
    onChangeInput,
    handleSubmit
  }
}

export default useForm
