/**
 * @category Actions
 * @module User
 */
import APIConnector from 'lib/api_connector'
import {ACCESS_OPTIONS} from 'lib/constants/users'
import {reportError} from 'lib/honeybadger'
import {initMixpanel} from 'lib/mixpanel'
import {identifyLogRocket} from 'lib/log_rocket'

const {
  REACT_APP_USER_TOKEN_COOKIE,
  REACT_APP_API_URL
} = process.env

const buildUser = (base) => {
  const {subAccountIds, campaignIds, ...user} = base
  switch (base.access) {
    case ACCESS_OPTIONS.account.id:
      return {
        ...user,
        subAccountIds: [...subAccountIds]
      }
    case ACCESS_OPTIONS.campaign.id:
      return {
        ...user,
        campaignIds: [...campaignIds]
      }
    case ACCESS_OPTIONS.full.id:
    default:
      return user
  }
}

/**
 * Read through the site cookies and find, if any, the user token stored there
 * @return {string} The user token inside the cookies or empty string if no
 * cookie is found.
 */
export const getUserToken = () => {
  const cookies = document.cookie.split('; ')
  const userCookie = cookies
    .find((cookie) => cookie.startsWith(REACT_APP_USER_TOKEN_COOKIE))
  if (!userCookie) {
    return ''
  }
  return userCookie.split('=')[1]
}

/**
 * Set the user token cookie to expire in a past date effectively removing
 * it
 */
export const deleteToken = () => {
  const expired = new Date(1970).toGMTString()
  document.cookie = `${REACT_APP_USER_TOKEN_COOKIE}= ;expires=${expired}`
}

/**
 * Call Login service and set up the API connector to use header token in all
 * future requests.
 * If remember me is true, save the token inside the cookies
 * @param {string} credentials.user - The user's email
 * @param {string} credentials.password- The user's password
 * @param {bool} remember - If true, save response in cookies
 * @return {object} The user info from the login service
 */
export const login = async ({user, password}, remember) => {
  const endpoint = `${REACT_APP_API_URL}/v1/login`
  const {data} = await APIConnector.post(endpoint, {user, password})
  if (remember) {
    document.cookie = `${REACT_APP_USER_TOKEN_COOKIE}=${data.token}`
  }
  return data
}

/**
 * Fetch the user data
 * @return {object} The user's info
 */
export const fetchUser = async () => {
  const endpoint = `${REACT_APP_API_URL}/v1/users`
  try {
    const {data} = await APIConnector.get(endpoint)
    initMixpanel(data)
    identifyLogRocket(data)
    return data
  } catch (err) {
    reportError(err, {action: 'getUser'})
    throw err
  }
}

/**
 * Request the list of users from an account
 * @param {string} id - The account id owner of the users
 * @return {object[]} The resulting list of users
 */
export const fetchAccountUsers = async (id) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/v1/accounts/${id}/users`
  try {
    const {data} = await APIConnector.get(endpoint)
    return data
  } catch (err) {
    reportError(err, {
      action: 'fetchAccountUsers',
      accountId: id
    })
    return []
  }
}

/**
 * Save changes made to an user under a specific account
 * @param {string} accountId - The id of the parent account
 * @param {object} data - The object to save
 * @return {object} The new user as returned by the server
 */
export const saveUser = async (accountId, data) => {
  const endpoint =
    `${process.env.REACT_APP_API_URL}/v1/accounts/${accountId}/users`
  let method = 'post'
  let finalEndpoint = endpoint
  const user = buildUser(data)
  try {
    if (data.userId) {
      method = 'put'
      finalEndpoint = `${endpoint}/${data.userId}`
    }
    const updated = await APIConnector[method](finalEndpoint, user)
    return updated.data
  } catch (err) {
    reportError(err, {
      action: 'saveUser',
      accountId: accountId,
      user: data
    })
    throw err
  }
}
