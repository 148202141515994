import {makeAutoObservable} from 'mobx'

import APIConnector from 'lib/api_connector'
import {
  getUserToken,
  fetchUser,
  fetchAccountUsers
} from 'actions/user_actions'

class UserStore {
  // TODO: Add a loading flag, the app should not show anything until user is
  // loaded
  user = {
    type: '',
    accountId: null,
    firstName: '',
    lastName: '',
    email: '',
    access: ''
  }
  users = []
  accountId
  token = ''

  constructor() {
    makeAutoObservable(this)
    this.setToken(getUserToken())
  }

  async fetchUser() {
    const user = await fetchUser()
    this.setUser(user)
  }

  async fetchUsers(accountId) {
    if (accountId === this.accountId) {
      return
    }
    this.accountId = accountId
    const users = await fetchAccountUsers(accountId)
    this.setUsers(users)
  }

  setToken(token) {
    if (!token) {
      return
    }
    APIConnector.defaults.headers.Authorization = `Bearer ${token}`
    this.token = token
  }

  setUser(user) {
    this.user = user
  }

  setUsers(users) {
    this.users = users
  }

  updateList(user) {
    const index = this.users.findIndex(({userId}) => userId === user.userId)
    if (index !== -1) {
      this.users[index] = user
    } else {
      this.users.push(user)
    }
  }

  get hasBillingPermission() {
    return this.user.billingMetricsEnabled
  }

  get hasPerformancePermission() {
    return this.user.performanceMetricsEnabled
  }

  get userType() {
    return this.user.type
  }

  get homePath() {
    if (this.user.accountId) {
      return `/accounts/${this.user.accountId}`
    }
    return '/'
  }

  clear() {
    this.user = {
      type: '',
      accountId: null,
      firstName: '',
      lastName: '',
      email: '',
      access: ''
    }
    this.users = []
    this.accountId = null
    this.token = ''
    APIConnector.defaults.headers.Authorization = null
  }
}

export default UserStore
