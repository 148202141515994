import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  Button,
  Modal,
  Form,
  Row,
  Col
} from 'react-bootstrap'
import {useParams} from 'react-router-dom'

import useForm from 'lib/hooks/form_hook'
import {RootContext} from 'stores/RootStore'
import {saveUser} from 'actions/user_actions'
import {ACCESS_OPTIONS} from 'lib/constants/users'
import {validateUser} from 'lib/validators/user_validator'
import {triggerEvent} from 'lib/mixpanel'

const BASE_CLASS = 'user-editor'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  billingMetricsEnabled: false,
  performanceMetricsEnabled: false,
  access: ACCESS_OPTIONS.full.id,
  subAccountIds: new Set(),
  campaignIds: new Set()
}

/**
 * A Modal component with the form to create or edit an user
 */
const UserEditor = ({show, onHide, user, accounts, campaigns}) => {
  const [formData, setData] = useState(user)
  const {accountId} = useParams()
  const {user: userContext, accountStore} = useContext(RootContext)

  const submit = async (values) => {
    const updated = await saveUser(accountId, values)
    triggerEvent('Invite client', {
      accountName: accountStore.accountName,
      userEmail: values.email
    })
    userContext.updateList(updated)
    onHide()
  }

  const {
    values,
    errors,
    pristine,
    valid,
    touched,
    submitting,
    onChangeInput,
    handleSubmit
  } = useForm({
    initialValues: formData,
    validator: validateUser,
    onSubmit: submit
  })

  useEffect(() => {
    const newUser = {
      ...user,
      subAccountIds: new Set(user.subAccountIds),
      campaignIds: new Set(user.campaignIds)
    }
    setData(newUser)
  }, [user])

  const updatePermissionsCheck = ({target}) => {
    onChangeInput({
      target: {
        value: target.checked,
        name: target.name
      }
    })
  }

  const updateSelectedChecks = ({target}) => {
    const type = `${target.dataset.type}Ids`
    const set = new Set([...values[type]])
    if (target.checked) {
      set.add(target.id)
    } else {
      set.delete(target.id)
    }
    onChangeInput({
      target: {
        value: set,
        name: type
      }
    })
  }

  const renderCheckbox = (type, id, label) => {
    const checked = values[`${type}Ids`].has(id)
    return (
      <Form.Check
        custom
        checked={checked}
        label={label}
        data-type={type}
        key={id}
        id={id}
        onChange={updateSelectedChecks}
      />
    )
  }

  const renderAccessLevel = () => {
    const {account, campaign} = ACCESS_OPTIONS
    switch (values.access) {
      case account.id:
        return accounts
          .map(({accountId, accountName}) => {
            return renderCheckbox('subAccount', accountId, accountName)
          })
      case campaign.id:
        return campaigns
          .map(({campaignId, campaignName}) => {
            return renderCheckbox('campaign', campaignId, campaignName)
          })
      default:
    }
  }

  const accessOptions = Object.values(ACCESS_OPTIONS)
    .map(({id, label}) => <option key={id} value={id}>{label}</option>)

  return (
    <Modal
      size='lg'
      aria-labelledby='user-editor-title'
      show={show}
      onHide={onHide}
      centered
    >
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header id='user-editor-title' closeButton>
          <Modal.Title className='text-info h5 pl-2'>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-4'>
          <Row>
            <Col xs={12} md={6} className={`${BASE_CLASS}__contact`}>
              <h6 className='text-primary'>Contact Info</h6>
              <Form.Group controlId='formFirstName'>
                <Form.Label className='text-muted'>First Name</Form.Label>
                <Form.Control
                  size='sm'
                  name='firstName'
                  placeholder='First Name'
                  value={values.firstName}
                  onChange={onChangeInput}
                  isInvalid={touched.firstName && errors.firstName}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='formLastName'>
                <Form.Label className='text-muted'>Last Name</Form.Label>
                <Form.Control
                  size='sm'
                  name='lastName'
                  placeholder='Last Name'
                  value={values.lastName}
                  onChange={onChangeInput}
                  isInvalid={touched.lastName && errors.lastName}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='formEmail'>
                <Form.Label className='text-muted'>Email</Form.Label>
                <Form.Control
                  size='sm'
                  name='email'
                  placeholder='user@domain.com'
                  type='email'
                  value={values.email}
                  onChange={onChangeInput}
                  disabled={values.userId}
                  isInvalid={touched.email && errors.email}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Label className='text-muted'>Access Details</Form.Label>
              <Form.Check
                name='performanceMetricsEnabled'
                id='formMetricsCheck'
                checked={values.performanceMetricsEnabled}
                label='Enable Performance Metrics'
                onChange={updatePermissionsCheck}
                custom
              />
              <Form.Check
                name='billingMetricsEnabled'
                id='formCogsCheck'
                checked={values.billingMetricsEnabled}
                label='Enable Billing Metrics'
                onChange={updatePermissionsCheck}
                custom
              />
            </Col>
            <Col xs={12} md={6} className={`${BASE_CLASS}__permissions`}>
              <h6 className='text-primary'>Permissions</h6>
              <Form.Group controlId='formAccessLevel'>
                <Form.Label className='text-muted'>Access Level</Form.Label>
                <Form.Control
                  size='sm'
                  as='select'
                  name='access'
                  value={values.access}
                  onChange={onChangeInput}
                  isInvalid={touched.access && errors.access}
                >
                  {accessOptions}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {errors.access}
                </Form.Control.Feedback>
              </Form.Group>
              {renderAccessLevel()}
            </Col>
          </Row>
          {errors.form &&
            <Alert variant='danger'>
              {errors.form}
            </Alert>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='link'
            className='text-danger'
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='outline-primary'
            className='custom-btn-outline-primary'
            disabled={pristine || submitting || !valid}
          >
            {submitting ? 'Saving' : 'Save'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

UserEditor.propTypes = {
  /** Used to manage when the modal should display */
  show: PropTypes.bool.isRequired,
  /** Function to execute when the modal is hidden */
  onHide: PropTypes.func.isRequired,
  /** The user options when editing, undefined when creating */
  user: PropTypes.object,
  /** List of sub accounts that can be assigned to a user */
  accounts: PropTypes.array,
  /** List of campaigns that can be assigned to a user */
  campaigns: PropTypes.array
}

UserEditor.defaultProps = {
  user: initialValues,
  accounts: [],
  campaigns: []
}

export default UserEditor
