import React, {useEffect, useContext, useCallback} from 'react'
import {
  useHistory,
  useParams
} from 'react-router-dom'

import {RootContext} from 'stores/RootStore'
import {presignedDownload} from 'actions/file_actions'
import {fetchReport} from 'actions/report_actions'
import {fetchAccount} from 'actions/account_actions'
import {fetchCampaign} from 'actions/campaign_actions'
import {triggerEvent} from 'lib/mixpanel'

const DirectDownloadReport = () => {
  const {reportId} = useParams()
  const {push} = useHistory()
  const {user} = useContext(RootContext)

  const downloadReport = useCallback(async () => {
    const report = await fetchReport(reportId)
    const campaign = await fetchCampaign(report.campaignId)
    const account = await fetchAccount(report.accountId)

    triggerEvent('Report download', {
      isEmail: true,
      reportName: report.reportName,
      accountName: account.accountName,
      campaignName: campaign.campaignName
    })
    await presignedDownload(reportId, '_self')
    push(`/campaigns/${campaign.campaignId}`)
    // TODO: Manage errors, when failed tell the user or send to 404
  }, [reportId, push])

  useEffect(() => {
    if (user.token) {
      downloadReport()
    }
  }, [user.token, downloadReport])

  return <div />
}

export default DirectDownloadReport
