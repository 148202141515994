import {createContext} from 'react'
import {makeAutoObservable} from 'mobx'

import UserStore from 'stores/UserStore'
import AccountStore from 'stores/AccountStore'
import CampaignStore from 'stores/CampaignStore'

class RootStore {
  user
  accountStore
  campaignStore

  constructor() {
    makeAutoObservable(this)
    this.user = new UserStore()
    this.accountStore = new AccountStore()
    this.campaignStore = new CampaignStore()
  }

  clear() {
    this.user.clear()
    this.accountStore.clear()
    this.campaignStore.clear()
  }
}

export const RootContext = createContext(RootStore)

export default RootStore
