import React, {useState, useEffect, useContext} from 'react'
import {Container, Button, Row, Col} from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import {observer} from 'mobx-react'

import BaseTable from 'components/BaseTable'
import CampaignFileUploader from 'displays/account/campaign/ReportUploader'
import {RootContext} from 'stores/RootStore'
import {formatDate} from 'lib/formatting'
import {presignedDownload} from 'actions/file_actions'
import {AM_TYPE_USER} from 'lib/constants/users'
import {triggerEvent} from 'lib/mixpanel'

const BASE_CLASS = 'campaign-wrapper'

/**
 * Displays a Campaign and t's shared reports
 */
const CampaignWrapper = observer(() => {
  const {campaignId} = useParams()
  const {user, accountStore, campaignStore} = useContext(RootContext)
  const [modalShow, setModalShow] = useState(false)
  useEffect(() => {
    campaignStore.fetchCampaign(campaignId)
    campaignStore.fetchCampaignReports(campaignId)
  }, [campaignId, campaignStore])
  useEffect(() => {
    accountStore.getAccount(campaignStore.accountId)
  }, [campaignStore.accountId, accountStore])

  const renderNameCell = ({value, row}) => {
    const {reportId, reportName} = row.original
    const downloadReport = async () => {
      try {
        await presignedDownload(reportId)
        triggerEvent('Report download', {
          reportName,
          accountName: accountStore.accountName,
          campaignName: campaignStore.name
        })
      } catch (err) {
        // TODO: Notify user of failed download
      }
    }
    return (
      <Button variant='link' onClick={downloadReport}>
        {value}
      </Button>
    )
  }

  // TODO: Change header strings to localized ones
  const columns = [
    {
      Header: 'Report Name',
      accessor: 'reportName',
      Cell: renderNameCell
    },
    {
      Header: 'Shared Date',
      accessor: 'sharedDate',
      Cell: ({value}) => formatDate(value)
    },
    {Header: 'Comments', accessor: 'comments'}
  ]

  const renderUploader = () => {
    return (
      <>
        <Button
          variant='outline-primary'
          className='custom-btn-outline-primary mb-3 float-right'
          onClick={() => setModalShow(true)}
        >
          Add report
        </Button>
        <CampaignFileUploader
          id={campaignId}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </>
    )
  }

  const canUpload = user.userType === AM_TYPE_USER

  return (
    <Container className={BASE_CLASS} fluid>
      <div className='mb-4'>
        <h5>{accountStore.accountName}: {campaignStore.name}</h5>
        <Row className='mt-4'>
          <Col md={2}>
            <h6>Start Date</h6>
            <small
              className='text-muted'
            >
              {campaignStore.flightStartDate}
            </small>
          </Col>
          <Col md={2}>
            <h6>End Date</h6>
            <small className='text-muted'>
              {campaignStore.flightEndDate}
            </small>
          </Col>
        </Row>
      </div>
      {canUpload && renderUploader()}
      <div className={`${BASE_CLASS}__campaigns`}>
        <BaseTable
          id='reports-table'
          data={campaignStore.reports}
          columns={columns}
        />
      </div>
    </Container>
  )
})

export default CampaignWrapper
