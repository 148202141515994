import React, {useEffect, useContext} from 'react'
import {
  Switch,
  Route,
  useHistory
} from 'react-router-dom'
import {observer} from 'mobx-react'

import AccountsDisplay from 'displays/AccountsDisplay'
import AccountWrapper from 'displays/account/AccountWrapper'
import UserAccess from 'displays/users/UserAccess'
import CampaignWrapper from 'displays/account/campaign/CampaignWrapper'
import {RootContext} from 'stores/RootStore'

const MainWrapper = observer(() => {
  const {push} = useHistory()
  const {user} = useContext(RootContext)

  useEffect(() => {
    if (user.homePath && user.homePath !== '/') {
      push(user.homePath)
    }
  }, [user.homePath, push])

  return (
    <Switch>
      <Route path='/accounts/:accountId'>
        <AccountWrapper />
      </Route>
      <Route path='/campaigns/:campaignId'>
        <CampaignWrapper />
      </Route>
      <Route path='/user_access/:accountId'>
        <UserAccess />
      </Route>
      <Route path='*'>
        <AccountsDisplay />
      </Route>
    </Switch>
  )
})

export default MainWrapper
