import React, {useContext} from 'react'
import {observer} from 'mobx-react'
import {Container, Button} from 'react-bootstrap'
import {
  Link,
  useHistory
} from 'react-router-dom'

import BaseTable from 'components/BaseTable'
import {RootContext} from 'stores/RootStore'

const labels = {
  dashboardTitle: 'Customer Dashboard Accounts',
  table: {
    name: 'Name',
    reports: 'Shared Reports',
    actions: 'Actions'
  }
}

const AccountsDisplay = observer(() => {
  const {push} = useHistory()
  const {accountStore} = useContext(RootContext)

  const renderNameCell = ({value, row}) => {
    return <Link to={`/accounts/${row.original.accountId}`}>{value}</Link>
  }

  const renderActions = ({row}) => {
    return (
      <Button
        size='sm'
        variant='outline-primary'
        className='custom-btn-outline-primary'
        onClick={() => push(`/user_access/${row.original.accountId}`)}
      >
        Access
      </Button>
    )
  }

  const columns = [
    {
      Header: labels.table.name,
      accessor: 'accountName',
      Cell: renderNameCell
    },
    {Header: labels.table.reports, accessor: 'reportCount'},
    {
      Header: labels.table.actions,
      accessor: '',
      Cell: renderActions
    }
  ]

  return (
    <Container fluid>
      <h5 className='mb-5'>{labels.dashboardTitle}</h5>
      <BaseTable
        id='accounts-table'
        data={accountStore.accounts}
        columns={columns}
      />
    </Container>
  )
})

export default AccountsDisplay
