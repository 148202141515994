/**
 * @category Actions
 * @module Account
 */
import APIConnector from 'lib/api_connector'

import {reportError} from 'lib/honeybadger'

const baseEndpoint = `${process.env.REACT_APP_API_URL}/v1`

/**
 * Request the list of accounts available
 * @return {object[]} The values returned by the server
 */
export const fetchAccounts = async () => {
  try {
    const endpoint = `${baseEndpoint}/accounts`
    const {data} = await APIConnector.get(endpoint)
    return data
  } catch (err) {
    reportError(err, {action: 'fetchAccounts'})
    // TODO: Notify user
    return []
  }
}

/**
 * Get the data of a single account using an id.
 * @param {string} id - The unique id of the requested account
 * @return {object} The found account.
 */
export const fetchAccount = async (id) => {
  try {
    const endpoint = `${baseEndpoint}/accounts/${id}`
    const {data} = await APIConnector.get(endpoint)
    return data
  } catch (err) {
    reportError(err, {action: 'fetchAccount', id})
    // TODO: Notify user
    return {}
  }
}

/**
 * Get the list of linked sub accounts of an specific account.
 * @param {string} id - The unique id of the requested account
 * @return {object} The found sub accounts.
 */
export const fetchSubAccounts = async (id) => {
  try {
    const endpoint = `${baseEndpoint}/accounts/${id}/sub_accounts`
    const {data} = await APIConnector.get(endpoint)
    return data
  } catch (err) {
    reportError(err, {action: 'fetchSubAccounts', id})
    // TODO: Notify user
    return []
  }
}
