const emptyFormatter = ({value}) => value

/**
 * Parses an array of data into a CSV formatted table, where each item is a row
 * @param {object[]} options.data - The source information to fill in the table
 * @param {object[]} options.columns - Columns configuration used to define the
 * table, each column must have "Header" and "accessor".
 * @return {string} - CSV valid string that represents a table
 */
export const buildTable = (options) => {
  const {
    data = [],
    columns = []
  } = options
  const rows = data.map((metric) => {
    const row = columns.map((col) => {
      const formatting = col.Cell || emptyFormatter
      return `"${formatting({value: metric[col.accessor]})}"`
    })
    return row.join(',')
  })
  const headers = columns.map(({Header}) => Header).join(',')
  return encodeURIComponent([headers, ...rows].join('\n'))
}

/**
 * Trigger a download of a CSV file to the user's file system
 * @param {string} data - The content of the csv in string form
 * @param {string} filename - Default name to give the file when downloading
 */
export const downloadCSV = (data, filename) => {
  const a = document.createElement('a')
  a.href = `data:text/csv;charset=utf-8,${data}`
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
