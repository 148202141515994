/**
 * @category Actions
 * @module Campaign
 */
import APIConnector from 'lib/api_connector'
import {reportError} from 'lib/honeybadger'

const baseEndpoint = `${process.env.REACT_APP_API_URL}/v1`

const adaptCampaigns = (data) => {
  return data.map((campaign) => {
    if (!campaign.metrics) {
      return campaign
    }
    const newMonthlyMetrics = campaign.metrics.monthly_metrics?.map(
      (month_metrics) => ({
        ...month_metrics,
        total_revenue: month_metrics.total_revenue || month_metrics.revenue}
      )
    ) || []
    return {
      ...campaign,
      metrics: {
        ...campaign.metrics,
        monthly_metrics: newMonthlyMetrics
      }
    }
  })
}

/**
 * Request the list of campaigns of an account
 * @param {string} accountId - The parent account id.
 * @return {object[]} The values returned by the server
 */
export const fetchCampaigns = async (accountId) => {
  try {
    const endpoint = `${baseEndpoint}/accounts/${accountId}/campaigns`
    const {data} = await APIConnector.get(endpoint)
    return adaptCampaigns(data)
  } catch (err) {
    reportError(err, {accountId, action: 'fetchCampaigns'})
    // TODO: Notify user
    return []
  }
}

/**
 * Request the list of reports shared to an specific campaign
 * @param {string} id - The the campaign id
 * @return {object[]} The values returned by the server
 */
export const fetchCampaignReports = async (id) => {
  try {
    const endpoint = `${baseEndpoint}/campaigns/${id}/reports`
    const {data} = await APIConnector.get(endpoint)
    return data
  } catch (err) {
    reportError(err, {id, action: 'fetchCampaignReports'})
    // TODO: Notify user
    return []
  }
}


/**
 * Get the data of a single campaign using an Id.
 * @param {string} id - The unique of the wanted campaign
 * @return {object} The resulting campaign
 */
export const fetchCampaign = async (id) => {
  try {
    const endpoint = `${baseEndpoint}/campaigns/${id}`
    const {data} = await APIConnector.get(endpoint)
    return data
  } catch (err) {
    // TODO: Notify user
    reportError(err, {action: 'fetchCampaign', id})
    return {}
  }
}

/**
 * Get the users that will receive a shared report for the given campaing
 * @param {string} id - Campaign id
 * @return {object} The list of users related to the campaign
 */
export const fetchRecipients = async (id) => {
  try {
    const endpoint = `${baseEndpoint}/campaigns/${id}/recipients`
    const {data} = await APIConnector.get(endpoint)
    return data
  } catch (err) {
    reportError(err, {action: 'fetchRecipients', id})
    return {}
  }
}
