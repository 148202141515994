import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as Router} from 'react-router-dom'

import App from 'App'
import RootStore, {RootContext} from 'stores/RootStore'
import {initLogRocket} from 'lib/log_rocket'
import {initHoneybadger} from 'lib/honeybadger'
import './index.scss'

const Root = new RootStore()

initLogRocket()
initHoneybadger()
ReactDOM.render(
  <React.StrictMode>
    <RootContext.Provider value={Root}>
      <Router>
        <App />
      </Router>
    </RootContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
