import React, {useState, useContext} from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  Button,
  Modal,
  Form
} from 'react-bootstrap'
import {CloudUpload} from 'react-bootstrap-icons'
import {observer} from 'mobx-react'

import useForm from 'lib/hooks/form_hook'
import {RootContext} from 'stores/RootStore'
import {presignedUpload} from 'actions/file_actions'
import {saveReport} from 'actions/report_actions'
import {validateReport} from 'lib/validators/report_validator'
import {triggerEvent} from 'lib/mixpanel'
import styles from 'displays/account/campaign/ReportUploader.module.scss'

const initialReport = {
  reportName: '',
  comments: '',
  tempUrl: '',
  extraRecipients: ''
}
const initialFile = {
  uploading: false,
  value: '',
  name: ''
}

// TODO: Change labels to localized variables
/**
 * Modal that holds the form to save a report
 */
const CampaignFileUploader = observer(({id, show, onHide}) => {
  const [file, setFile] = useState(initialFile)
  const {campaignStore, accountStore} = useContext(RootContext)

  const onClose = () => {
    setFile(initialFile)
    onHide()
  }

  const submit = async ({reportName, comments, tempUrl, extraRecipients}) => {
    const newReport = await saveReport(id, {
      reportName,
      comments,
      tempUrl,
      extraRecipients
    })
    triggerEvent('Report upload', {
      reportName,
      accountName: accountStore.accountName,
      campaignName: campaignStore.name
    })
    campaignStore.addReport(newReport)
    onClose()
  }

  const {
    values,
    errors,
    pristine,
    valid,
    touched,
    submitting,
    onChangeInput,
    handleSubmit
  } = useForm({
    initialValues: initialReport,
    validator: validateReport,
    onSubmit: submit
  })

  const onFileChange = async ({target}) => {
    const file = target.files[0]
    if (!file) {
      return
    }
    setFile({uploading: true})
    try {
      const tempUrl = await presignedUpload(file, {
        contentType: file.type
      })
      onChangeInput({
        target: {
          name: 'tempUrl',
          value: tempUrl
        }
      })
      setFile({
        uploading: false,
        value: target.value,
        name: file.name
      })
    } catch (err) {
      onChangeInput({
        target: {
          name: 'tempUrl',
          value: err
        }
      })
      setFile(initialFile)
    }
  }

  const recipients = (
    <div className={styles.recipients}>
      <label className='text-muted'>Recipients</label>
      <div className='text-muted mb-2'>
        {campaignStore.recipients.map(({email}) => email).join(', ')}
      </div>
    </div>
  )

  return (
    <Modal
      size='md'
      aria-labelledby='uploader-modal-title'
      show={show}
      onHide={onClose}
      centered
    >
      <Form name='report-form' onSubmit={handleSubmit} noValidate>
        <Modal.Header
          id='uploader-modal-title'
          closeButton
        >
          <Modal.Title className='text-info h5 pl-2'>Add Report</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-4'>
          <Form.Group controlId='formReportName'>
            <Form.Label className='text-muted'>Report name</Form.Label>
            <Form.Control
              name='reportName'
              placeholder='Type report name here'
              value={values.reportName}
              onChange={onChangeInput}
              isInvalid={touched.reportName && errors.reportName}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.reportName}
            </Form.Control.Feedback>
          </Form.Group>
          {recipients}
          <Form.Group controlId='extraRecipients'>
            <Form.Label className='text-muted'>Extra recipients</Form.Label>
            <Form.Control
              name='extraRecipients'
              placeholder='Add emails separated by a comma'
              value={values.extraRecipients}
              onChange={onChangeInput}
              isInvalid={touched.extraRecipients && errors.extraRecipients}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.extraRecipients}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId='formReportComments'>
            <Form.Label className='text-muted'>Report Comments</Form.Label>
            <Form.Control
              name='comments'
              as='textarea'
              rows={3}
              placeholder='Type comments here'
              value={values.comments}
              onChange={onChangeInput}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className='text-muted'>
              <CloudUpload size={20} />
              <span className='ml-2'>Upload your attachment</span>
            </Form.Label>
            <Form.File id='campaign-report-uploader' custom>
              <Form.File.Input
                accept={['.pdf', '.ppt', '.pptx', '.xls', '.csv']}
                placeholder='Report file'
                value={file.value}
                onChange={onFileChange}
                isInvalid={touched.tempUrl && errors.tempUrl}
                disabled={file.uploading}
              />
              <Form.File.Label
                data-browse={file.uploading ? 'Uploading' : 'Browse'}
              >
                {file.name || ''}
              </Form.File.Label>
              <Form.Control.Feedback type='invalid'>
                {errors.tempUrl}
              </Form.Control.Feedback>
            </Form.File>
          </Form.Group>
          {errors.form &&
            <Alert variant='danger'>
              {errors.form}
            </Alert>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='link'
            className='text-danger'
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='outline-primary'
            className='custom-btn-outline-primary'
            disabled={pristine || submitting || !valid}
          >
            {submitting ? 'Saving' : 'Save'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
})

CampaignFileUploader.propTypes = {
  /** The id of the campaign for this report */
  id: PropTypes.string.isRequired,
  /** Indicates if the modal is visible, required by react-bootstrap */
  show: PropTypes.bool.isRequired,
  /** To call whenver the modal hides, required by react-bootstrap */
  onHide: PropTypes.func.isRequired
}

export default CampaignFileUploader
