import React, {useState, useContext} from 'react'
import {
  Alert,
  Form,
  Button,
  InputGroup
} from 'react-bootstrap'
import {
  EnvelopeOpen,
  Lock
} from 'react-bootstrap-icons'

import useForm from 'lib/hooks/form_hook'
import {login} from 'actions/user_actions'
import {RootContext} from 'stores/RootStore'
import styles from 'displays/Login.module.scss'

const initialValues = {user: '', password: ''}
const validator = ({user, password}) => {
  const errors = {}
  if (!user) {
    errors.user = 'Please introduce your email'
  }
  if (!password) {
    errors.password = 'Please introduce your password'
  }
  return errors
}

const Login = () => {
  const [remember, setRemember] = useState(true)
  const {user} = useContext(RootContext)

  const onSubmit = async (values) => {
    const {token} = await login(values, remember)
    user.setToken(token)
  }

  const {
    values,
    errors,
    valid,
    touched,
    submitting,
    onChangeInput,
    handleSubmit
  } = useForm({validator, initialValues, onSubmit})

  const renderLogo = () => {
    return (
      <div>
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          alt='aki logo'
          width='70'
        />
      </div>
    )
  }

  const renderMessage = () => {
    return (
      <div>
        <h2 className='text-primary'>Hey there!</h2>
        <h2 className='text-dark'>Your dashboard awaits.</h2>
        <small className='text-muted'>Log in using your work email.</small>
      </div>
    )
  }

  const renderForm = () => {
    return (
      <div>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId='userEmail'>
            <Form.Label className='text-muted'>Email</Form.Label>
            <InputGroup size='sm'>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <EnvelopeOpen sie={16} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                name='user'
                placeholder='user@domain.com'
                value={values.user}
                onChange={onChangeInput}
                isInvalid={touched.user && errors.user}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.user}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group controlId='userPassword'>
            <Form.Label className='text-muted'>Password</Form.Label>
            <InputGroup size='sm'>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <Lock sie={16} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                size='sm'
                name='password'
                placeholder='Start typing...'
                type='password'
                value={values.password}
                onChange={onChangeInput}
                isInvalid={touched.password && errors.password}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.password}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Check
            custom
            label='Remember me'
            key='remmber-me'
            id='remember-me'
            className='mt-4'
            checked={remember}
            onChange={({target}) => setRemember(target.checked)}
          />
          {errors.form && !submitting &&
            <Alert variant='danger'>
              Credentials are incorrect, please try again
            </Alert>
          }
          <Button
            type='submit'
            size='lg'
            variant='outline-primary'
            className='mt-4 custom-btn-outline-primary'
            disabled={submitting || !valid}
          >
            Sign in
          </Button>
        </Form>
      </div>
    )
  }

  return (
    <div className={styles.login}>
      <div className={styles['left-column']}>
        {renderLogo()}
        {renderMessage()}
        {renderForm()}
      </div>
      <div className={styles['right-column']}>
        <div className={styles.atom}>
          <img
            src={`${process.env.PUBLIC_URL}/images/aki_atom_white.svg`}
            alt='aki atom'
          />
        </div>
      </div>
    </div>
  )
}

export default Login
