/**
 * @category Actions
 * @module Report
 */
import APIConnector from 'lib/api_connector'
import {reportError} from 'lib/honeybadger'

const parseRecipients = (emails) => {
  if (!emails) {
    return []
  }
  return emails.split(',').map((mail) => mail.trim())
}

export const saveReport = async (id, report) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/v1/campaigns/${id}/reports`
  try {
    const reportToSave = {
      ...report,
      extraRecipients: parseRecipients(report.extraRecipients)
    }
    const {data} = await APIConnector.post(endpoint, reportToSave)
    return data
  } catch (err) {
    reportError(err, {
      action: 'saveReport',
      campaign: id,
      report
    })
    throw err
  }
}

/**
 * Get the data of a single report.
 * @param {string} id - The unique id of the requested report
 * @return {object} The found report.
 */
export const fetchReport = async (id) => {
  try {
    const endpoint = `${process.env.REACT_APP_API_URL}/v1/reports/${id}`
    const {data} = await APIConnector.get(endpoint)
    return data
  } catch (err) {
    reportError(err, {action: 'fetchReport', id})
    return {}
  }
}
