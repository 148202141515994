/**
 * @category Actions
 * @module File
 */
import axios from 'axios'

import APIConnector from 'lib/api_connector'
import {reportError} from 'lib/honeybadger'

/**
 * Upload a file to S3 using a presigned url
 * @param {File} file - The document to save in S3
 * @param {object} meta - Metadata of the file
 * @param {string} meta.contentType - The file's mimetype
 * @return {string} The resulting URL where the file ended up
 */
export const presignedUpload = async (file, {contentType}) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/v1/presigned`
  try {
    const {data} = await APIConnector.post(endpoint, null)
    await axios.put(data.url, file, {
      headers: {
        'Content-Type': contentType
      }
    })
    return data.url.split('?')[0]
  } catch (err) {
    reportError(err, {
      action: 'presignedUpload',
      file: file.name,
      contentType
    })
    throw err
  }
}

/**
 * Download a report file based on it's id and name, first request a signed url
 * and then fetch the resulting S3 url.
 * @param {string} id - The report's id
 */
export const presignedDownload = async (id, target = '_blank') => {
  const endpoint = `${process.env.REACT_APP_API_URL}/v1/reports/${id}/presigned`
  try {
    const {data} = await APIConnector.get(endpoint)
    window.open(data.url, target)
  } catch (err) {
    reportError(err, {
      id,
      action: 'presignedDownload'
    })
    throw err
  }
}
