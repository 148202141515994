/** @module Formatting */
/**
 * Render a date into a user friendly string
 * @param {string|Date} date - The original date to format
 * @return {string} Localized date string
 */
export const formatDate = (date) => {
  const timestamp = Date.parse(date)
  if (Number.isNaN(timestamp)) {
    return ''
  }
  return new Date(date).toLocaleDateString(undefined, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  })
}

const DEFAULT_DATE_OPTIONS = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric'
}
/**
 * Similar to formatDate but it ensures that the date provided is managed as UTC
 * @param {string|Date} date - The original date to format
 * @param {string} [locale] - A string to apply a specific localized format
 * @param {object} [options = DEFAULT_DATE_OPTIONS] - Custom formatting opts
 * @return {string} A localized date string
 */
export const formatDateUTC = (date, locale, options = DEFAULT_DATE_OPTIONS) => {
  const timestamp = Date.parse(date)
  if (Number.isNaN(timestamp)) {
    return ''
  }
  const tempDate = new Date(date)
  const timeDiff = tempDate.getTimezoneOffset() * 60000
  const adjustedDate = new Date(tempDate.valueOf() + timeDiff)
  return adjustedDate.toLocaleDateString(locale, options)
}


/**
 * Given a number, format it as percentage using the browse's locale style
 * @param {number} number - The value to format into percentage
 * @param {number} [decimals=2] - The minimum value of decimals to show
 * @return {string} The formatted value
 */
export const formatPercentage = (number = 0, decimals = 2) => {
  return number.toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: decimals
  })
}

/**
 * Given a number, format it as user friendly using the browse's locale style
 * @param {number} number - The value to format
 * @param {object} [opts = {}] - Extra configurations to apply to the formatting
 * @return {string} The formatted value
 */
export const formatNumber = (number = 0, opts = {}) => {
  return number.toLocaleString(undefined, opts)
}

/**
 * Given a number, format it as currency using the browse's locale style
 * @param {number} number - The value to format
 * @param {string} [currency = 'USD'] - The symbol to use.
 * @return {string} The formatted value
 */
export const formatDollars = (number = 0, currency = 'USD') => {
  return number.toLocaleString(undefined, {
    style: 'currency',
    currency
  })
}

/**
 * Given a string, make the first letter of each provided word to be upper case
 * @param {string} string - The original text
 * @return {string} The resulting text
 */
export const capitalize = (string) => {
  if (!string) {
    return ''
  }
  return string
    .split(' ')
    .map((s) => `${s[0].toUpperCase()}${s.slice(1)}`)
    .join(' ')
}
