/* eslint no-console: "off" */
/** @module Honeybadger */
import HB from 'honeybadger-js'
import LogRocket from 'logrocket'

const getRaw = (data = {}) => {
  let raw = ''
  try {
    raw = JSON.stringify(data, null, 2)
  } catch (err) {
    raw = err
  }
  return raw
}

const getRawStackTrace = (error = {}) => {
  const stack = error.stack || (new Error()).stack
  return getRaw(stack)
}

const logInHoneybadger = (error, context = {}) => {
  LogRocket.getSessionURL((sessionURL) => {
    const updatedContext = {
      ...(error.context || {}),
      ...context,
      event: getRaw(context.event || {}),
      sessionURL,
      rawError: getRaw(error),
      rawStackTrace: getRawStackTrace(error),
      url: window && window.location && window.location.href
    }
    const updatedError = {
      name: error.logMessage || error.message || 'Unknown error',
      stack: error.stack || {},
      message: error.logMessage || error.message || 'Unknown error cause'
    }
    HB.notify(updatedError, {
      context: updatedContext
    })
  })
}

const getLogger = (error) => {
  const {REACT_APP_HONEYBADGER_API_KEY} = process.env
  const status = error.status || (error.response && error.response.status) || 0
  if (REACT_APP_HONEYBADGER_API_KEY) {
    if (status >= 400 && status < 500) {
      return console.log
    }
    return logInHoneybadger
  }
  return console.log
}

/**
 * Manage error reporting, either send them to Honeybadger or, if not configured
 * to show them in dev console
 * @param {Error|string} error - The actual caught error
 * @param {object} [context] - Extra details to the error.
 */
export const reportError = (error, context) => {
  getLogger(error)(error, context)
}

/**
 * Initial setup for HB.
 */
export const initHoneybadger = () => {
  const {
    REACT_APP_HONEYBADGER_API_KEY,
    REACT_APP_ENVIRONMENT,
    REACT_APP_REVISION
  } = process.env
  if (REACT_APP_HONEYBADGER_API_KEY) {
    HB.configure({
      apiKey: REACT_APP_HONEYBADGER_API_KEY,
      environment: REACT_APP_HONEYBADGER_API_KEY,
      revision: REACT_APP_REVISION || REACT_APP_ENVIRONMENT,
      onerror: false,
      async: true
    })
    window.addEventListener('error', (event) => {
      const {error} = event
      if (error) {
        reportError(event.error, {event})
      }
    })
    window.addEventListener('unhandledrejection', (event) => {
      if (event.reason) {
        reportError(event.reason, {event})
      }
    })
  }
}
