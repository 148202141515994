import React, {useState, useContext, useEffect} from 'react'
import {observer} from 'mobx-react'
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  Link
} from 'react-router-dom'
import {Navbar} from 'react-bootstrap'
import {BoxArrowRight} from 'react-bootstrap-icons'

import Login from 'displays/Login'
import Sidebar from 'components/Sidebar'
import MainWrapper from 'displays/MainWrapper'
import DirectDownloadReport from 'displays/DirectDownloadReport'
import {RootContext} from 'stores/RootStore'
import {deleteToken} from 'actions/user_actions'
import {triggerEvent} from 'lib/mixpanel'
import styles from 'App.module.scss'

const App = observer(() => {
  const {push} = useHistory()
  const {pathname} = useLocation()
  const [prevLocation, setLocation] = useState(pathname)
  const stores = useContext(RootContext)

  useEffect(() => {
    if (!stores.user.token) {
      push('/login')
    } else {
      stores.user.fetchUser()
      stores.accountStore.fetchAccounts()
      if (prevLocation === '/login') {
        push('/')
      } else {
        push(prevLocation)
      }
    }
  }, [
    stores.user,
    stores.user.token,
    stores.accountStore,
    push,
    prevLocation
  ])

  useEffect(() => {
    if (pathname !== '/login') {
      if (!stores.user.token) {
        setLocation(pathname)
      }
      // Only trigger once the user has been loaded and mixpanel init
      if (stores.user.user.userId) {
        triggerEvent('Page view', {path: pathname})
      }
    }
  }, [stores.user.token, stores.user.user.userId, pathname])

  const logout = () => {
    stores.clear()
    push('/login')
    deleteToken()
  }

  return (
    <Switch>
      <Route path='/login'>
        <Login />
      </Route>
      <Route path='/report/:reportId'>
        <DirectDownloadReport />
      </Route>
      <Route path='*'>
        <div className={styles.App}>
          <Sidebar src={`${process.env.PUBLIC_URL}/images/logo.png`} />
          <main>
            <Navbar bg='light' className='pt-3'>
              <Navbar.Collapse className='justify-content-end'>
                <Navbar.Text>
                  <Link to='#'>
                    <BoxArrowRight size={20} onClick={logout} />
                  </Link>
                </Navbar.Text>
              </Navbar.Collapse>
            </Navbar>
            <MainWrapper />
          </main>
        </div>
      </Route>
    </Switch>
  )
})

export default App
