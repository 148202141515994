import React, {useState, useEffect, useContext} from 'react'
import {Container, Button} from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import {observer} from 'mobx-react'

import BaseTable from 'components/BaseTable'
import UserEditor from 'displays/users/UserEditor'
import {RootContext} from 'stores/RootStore'
import {
  capitalize,
  formatDate
} from 'lib/formatting'
import {ACCESS_OPTIONS} from 'lib/constants/users'

/**
 * Holds the table for managing users based on their account and adding new ones
 */
const UserAccess = observer(() => {
  const {user, accountStore, campaignStore} = useContext(RootContext)
  const {accountId} = useParams()
  const [showEditor, setShowEditor] = useState(false)
  const [currentUser, setCurrentUser] = useState(undefined)
  useEffect(() => {
    accountStore.getAccount(accountId)
    accountStore.getSubAccounts(accountId)
  }, [accountId, accountStore])
  useEffect(() => {
    campaignStore.fetchCampaigns(accountId)
  }, [accountId, campaignStore])

  useEffect(() => {user.fetchUsers(accountId)}, [accountId, user])

  const onShowEditor = () => setShowEditor(true)

  const editUser = (user) => {
    setCurrentUser(user)
    onShowEditor()
  }

  const renderActions = ({row}) => {
    return (
      <Button
        size='sm'
        variant='outline-primary'
        className='custom-btn-outline-primary'
        onClick={() => editUser(row.original)}
      >
        Edit
      </Button>
    )
  }

  const columns = [
    {Header: 'First Name', accessor: 'firstName'},
    {Header: 'Last Name', accessor: 'lastName'},
    {Header: 'Email', accessor: 'email'},
    {
      Header: 'Access Level',
      accessor: 'access',
      Cell: ({value}) => ACCESS_OPTIONS[value].label
    },
    {
      Header: 'Date Created',
      accessor: 'createdAt',
      Cell: ({value}) => formatDate(value)
    },
    {
      Header: 'Last Login',
      accessor: 'lastLoginAt',
      Cell: ({value}) => formatDate(value)
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => capitalize(value)
    },
    {
      Header: 'Actions',
      accessor: '',
      Cell: renderActions
    }
  ]

  const onHideEditor = () => {
    setShowEditor(false)
    setCurrentUser(undefined)
  }

  return (
    <Container fluid>
      <h5 className='mb-5'>Access for: {accountStore.accountName}</h5>
      <Button
        size='sm'
        variant='outline-primary'
        className='custom-btn-outline-primary mb-3 float-right'
        onClick={onShowEditor}
      >
        Add User
      </Button>
      <UserEditor
        show={showEditor}
        onHide={onHideEditor}
        user={currentUser}
        accounts={accountStore.subAccounts}
        campaigns={campaignStore.campaigns}
      />
      <BaseTable
        id='users-table'
        columns={columns}
        data={user.users}
      />
    </Container>
  )
})

export default UserAccess
