import axios from 'axios'

const APIConnector = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

APIConnector.interceptors.request.use((config) => {
  const isLogin = config.url === `${config.baseURL}/v1/login`
  if (!isLogin && !config.headers.Authorization) {
    return {
      ...config,
      cancelToken: new axios.CancelToken((c) => c('No auth set'))
    }
  }
  return config
})

export default APIConnector
