import React, {useContext} from 'react'
import {observer} from 'mobx-react'
import {CloudDownloadFill} from 'react-bootstrap-icons'
import {Button} from 'react-bootstrap'

import BaseTable from 'components/BaseTable'
import {triggerEvent} from 'lib/mixpanel'
import {RootContext} from 'stores/RootStore'
import {buildTable, downloadCSV} from 'lib/csv_utils'
import {
  formatNumber,
  formatDollars,
  formatPercentage
} from 'lib/formatting'
import styles from 'displays/account/CogsReport.module.scss'

/**
 * The configurations for data related to billing metrics
 */
const COGSReport = observer(() => {
  const {
    campaignStore,
    accountStore
  } = useContext(RootContext)
  const columns = [
    {Header: 'Brand', accessor: 'brand'},
    {Header: 'Campaign Name', accessor: 'campaign'},
    {Header: 'Flight Dates', accessor: 'flightDates'},
    {Header: 'Last Updated', accessor: 'lastUpdate'},
    {Header: 'Month', accessor: 'month'},
    {
      Header: 'Impressions',
      accessor: 'impressions',
      Cell: ({value}) => formatNumber(value)
    },
    {
      Header: 'Total Revenue',
      accessor: 'total_revenue',
      Cell: ({value}) => formatDollars(value)
    },
    {
      Header: 'Creative Revenue',
      accessor: 'creative_revenue',
      Cell: ({value}) => formatDollars(value)
    },
    {
      Header: 'Media Revenue',
      accessor: 'media_revenue',
      Cell: ({value}) => formatDollars(value)
    },
    {
      Header: 'Media Cost',
      accessor: 'media_cost',
      Cell: ({value}) => formatDollars(value)
    },
    {
      Header: 'Media Profit',
      accessor: 'media_profit',
      Cell: ({value}) => formatDollars(value)
    },
    {
      Header: 'Media Margin',
      accessor: 'media_margin',
      Cell: ({value}) => formatPercentage(value)
    },
    {
      Header: 'RM Fees',
      accessor: 'rich_media_fees',
      Cell: ({value}) => formatDollars(value)
    },
    {
      Header: 'Platform Fees',
      accessor: 'platform_fees',
      Cell: ({value}) => formatDollars(value)
    },
    {
      Header: '3P Data Fees',
      accessor: 'third_party_data_fees',
      Cell: ({value}) => formatDollars(value)
    },
    {
      Header: 'Ad Serving Fees',
      accessor: 'ad_serving_fees',
      Cell: ({value}) => formatDollars(value)
    },
    {
      Header: 'Net Profit',
      accessor: 'net_profit',
      Cell: ({value}) => formatDollars(value)
    },
    {
      Header: 'Net Margin',
      accessor: 'net_margin',
      Cell: ({value}) => formatPercentage(value)
    },
    {
      Header: 'FD Media Profit',
      accessor: 'fd_media_profit',
      Cell: ({value}) => formatDollars(value)
    }
  ]

  const getRowProps = (row) => {
    if (row.original.isTotals) {
      return {
        className: styles['totals-row']
      }
    }
    return {}
  }

  const buildCSVData = () => {
    const table = buildTable({columns, data: campaignStore.billingMetrics})
    const title = `${accountStore.accountName}.csv`
    downloadCSV(table, title)
    triggerEvent('Download Billing Metrics')
  }

  return (
    <>
      <Button className={styles['download-btn']} onClick={buildCSVData}>
        <CloudDownloadFill size={10} />
      </Button>
      <BaseTable
        id='cogs-table'
        columns={columns}
        data={campaignStore.billingMetrics}
        getRowProps={getRowProps}
      />
    </>
  )
})

export default COGSReport
