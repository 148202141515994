import {makeAutoObservable} from 'mobx'

import {
  fetchCampaigns,
  fetchCampaign,
  fetchCampaignReports,
  fetchRecipients
} from 'actions/campaign_actions'
import {formatDateUTC} from 'lib/formatting'

const getDateValues = (dateString) => {
  const [year, month] = dateString.split('-')
  const lastDay = new Date(year, month, 0)
  const start = formatDateUTC(dateString)
  const end = formatDateUTC(lastDay)
  return {
    flightDates: `${start} - ${end}`,
    month: formatDateUTC(lastDay, undefined, {month: 'long'})
  }
}

class CampaignStore {
  accountId
  campaign = {}
  campaigns = []
  billingMetrics = []
  reports = []
  recipients = []

  constructor() {
    makeAutoObservable(this)
  }

  async fetchCampaigns(accountId) {
    const newCampaigns = await fetchCampaigns(accountId)
    this.accountId = accountId
    this.setCampaigns(newCampaigns)
    this.setBillingMetrics(newCampaigns)
  }

  async fetchCampaign(id) {
    if (!id || id === this.campaign.campaignId) {
      return
    }
    const newCampaign = await fetchCampaign(id)
    this.accountId = newCampaign.accountId
    this.setCampaign(newCampaign)
    const recipients = await fetchRecipients(id)
    this.setRecipients(recipients)
  }

  async fetchCampaignReports(id) {
    if (!id || id === this.campaign.campaignId) {
      return
    }
    const reports = await fetchCampaignReports(id)
    this.setReports(reports)
  }

  setCampaigns(campaigns) {
    this.campaigns = campaigns
  }

  setCampaign(campaign) {
    this.campaign = campaign
  }

  setReports(reports) {
    this.reports = reports
  }

  setRecipients(recipients) {
    this.recipients = recipients
  }

  setBillingMetrics(campaigns) {
    const reports = campaigns.reduce((acc, campaign) => {
      if (!campaign.metrics) {
        return acc
      }
      const lastUpdate = formatDateUTC(campaign.lastMetricsUpdate)
      const {
        totals,
        monthly_metrics,
        brand,
        campaign_name,
        flight_dates
      } = campaign.metrics
      const months = monthly_metrics.map(({month, ...metrics}) => {
        return {
          ...metrics,
          ...getDateValues(month),
          campaign: campaign_name,
          lastUpdate,
          brand
        }
      })
      const start = formatDateUTC(flight_dates.start)
      const end = formatDateUTC(flight_dates.end)
      const summary = {
        ...totals,
        brand,
        lastUpdate,
        isTotals: true,
        month: 'Total',
        campaign: campaign_name,
        flightDates: `${start} - ${end}`
      }
      return [...acc, ...months, summary]
    }, [])
    this.billingMetrics = reports
  }

  addReport(report) {
    this.reports.push(report)
  }

  get name() {
    return this.campaign.campaignName || ''
  }

  get flightDate() {
    const start = formatDateUTC(this.campaign.startDate)
    const end = formatDateUTC(this.campaign.endDate)
    return `Start date: ${start} End date: ${end}`
  }

  get flightStartDate() {
    return formatDateUTC(this.campaign.startDate)
  }

  get flightEndDate() {
    return formatDateUTC(this.campaign.endDate)
  }

  clear() {
    this.accountId = null
    this.campaign = {}
    this.campaigns = []
    this.reports = []
    this.billingMetrics = []
  }
}

export default CampaignStore
