/** @module LogRocket */
import LogRocket from 'logrocket'

/**
 * When api key is provided, setup LogRocket
 * @method
 */
export const initLogRocket = (a) => {
  const {
    REACT_APP_LOG_ROCKET_API_KEY,
    REACT_APP_REVISION,
    REACT_APP_ENVIRONMENT
  } = process.env
  if (REACT_APP_LOG_ROCKET_API_KEY) {
    LogRocket.init(REACT_APP_LOG_ROCKET_API_KEY, {
      release: `${REACT_APP_ENVIRONMENT}-${REACT_APP_REVISION}`
    })
  }
}

/**
 * When user is logged, identify it in the LR API.
 * @param {object} user - The logged user to use under the LR session
 */
export const identifyLogRocket = (user) => {
  const {REACT_APP_LOG_ROCKET_API_KEY} = process.env
  if (REACT_APP_LOG_ROCKET_API_KEY) {
    LogRocket.identify(user.userId, user)
  }
}
