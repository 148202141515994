import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {Navbar} from 'react-bootstrap'
import {House} from 'react-bootstrap-icons'
import {observer} from 'mobx-react'
import {useHistory} from 'react-router-dom'

import {RootContext} from 'stores/RootStore'
import styles from 'components/Sidebar.module.scss'

const Sidebar = observer(({src}) => {
  const {push} = useHistory()
  const {user} = useContext(RootContext)
  const goHome = () => push(user.homePath)

  return (
    <div className={styles.sidebar}>
      <Navbar.Brand className={styles.brand}>
        <img src={src} alt='aki_atom' width={70} />
      </Navbar.Brand>
      <div
        className={`${styles['menu-item']} ${styles.active} pl-4`}
        onClick={goHome}
      >
        <House size={20} className='mr-3' />
        <span>Dashboard</span>
      </div>
    </div>
  )
})

Sidebar.propTypes = {
  /** The url src of brand image */
  src: PropTypes.string.isRequired
}

export default Sidebar
