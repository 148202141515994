export const ACCESS_OPTIONS = {
  full: {
    id: 'full',
    label: 'Full Access'
  },
  account: {
    id: 'account',
    label: 'Account Access'
  },
  campaign: {
    id: 'campaign',
    label: 'Campaign'
  }
}

export const AM_TYPE_USER = 'AM'
export const CLIENT_TYPE_USER = 'Client'
