/**
 * @category Validators
 * @module User
 */

const EMAIL_REGEXP = /\S+@\S+\.\S+/
const validteEmails = (emails = '') => {
  if (!emails) {
    return true
  }
  return emails.split(',').every((mail) => EMAIL_REGEXP.test(mail))
}

/**
 * Validates a report follows these rules
 *  - A name is provided
 *  - A tempUrl is provided and it's not an Error object
 *  - If extraRecipients has value, all the emails provided are valid
 * @param {object} report - The values to validate
 * @return {object} The list of fields with errors found, if the report is valid
 * returns an empty object
 */
export const validateReport = ({reportName, tempUrl, extraRecipients}) => {
  const errors = {}
  if (!reportName) {
    errors.reportName = 'Please give a name to the report'
  }
  if (!tempUrl) {
    errors.tempUrl = 'Please upload a PDF or PPT report file'
  }
  if (!validteEmails(extraRecipients)) {
    errors.extraRecipients = 'Please introduce valid emails as recipients'
  }
  if (tempUrl instanceof Error) {
    errors.tempUrl = 'Failed to upload the report file, please try again'
  }
  return errors
}
