import {makeAutoObservable} from 'mobx'

import {
  fetchAccounts,
  fetchSubAccounts,
  fetchAccount
} from 'actions/account_actions'
import {
  INITIAL_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
  ERROR_STATUS
} from 'lib/constants/requests'

class AccountStore {
  // TODO: Keep a loading flags to prevent fetching on a pending request
  accounts = []
  subAccounts = []
  account = {}
  requests = {
    account: {},
    accounts: {},
    subAccounts: {
      id: null,
      status: INITIAL_STATUS
    }
  }

  constructor() {
    makeAutoObservable(this)
  }

  async fetchAccounts() {
    const accounts = await fetchAccounts()
    this.setAccounts(accounts)
  }

  async fetchAccount(id) {
    if (!id || id === this.account.accountId) {
      return
    }
    const account = await fetchAccount(id)
    this.setAccount(account)
  }

  async fetchSubAccounts(id) {
    if (!id) {
      return
    }
    try {
      this.requests.subAccounts.status = LOADING_STATUS
      this.requests.subAccounts.id = id
      const accounts = await fetchSubAccounts(id)
      this.setSubAccounts(accounts)
      this.requests.subAccounts.status = SUCCESS_STATUS
    } catch (e) {
      this.requests.subAccounts.status = ERROR_STATUS
    }
  }

  setAccounts(accounts) {
    this.accounts = accounts
  }

  setAccount(account) {
    this.account = account
  }

  setSubAccounts(accounts) {
    this.subAccounts = accounts
  }

  getAccount(id) {
    if (this.account && this.account.accountId === id) {
      return this.account
    }
    this.fetchAccount(id)
    return {}
  }

  getSubAccounts(id) {
    // TODO: Move to a shared function
    switch (this.requests.subAccounts.status) {
      case LOADING_STATUS:
        return []
      case SUCCESS_STATUS: {
        if (this.requests.subAccounts.id === id) {
          return this.subAccounts
        }
        this.fetchSubAccounts(id)
        return []
      }
      case ERROR_STATUS:
      case INITIAL_STATUS:
      default:
        this.fetchSubAccounts(id)
        return []
    }
  }

  get accountName() {
    return this.account.accountName || ''
  }

  get hasBillingReport() {
    return this.account.hasCogs
  }

  clear() {
    this.accounts = []
    this.subAccounts = []
    this.account = {}
  }
}

export default AccountStore
